import React from "react";

const checkout = ({ location }) => {
  const url = location.state?.url;

  return (
    <iframe
      src={url}
      style={{ width: "100%", height: "1000px", border: "none" }}
    />
  );
};

export default checkout;
